body, html {
    height: 100%;
    min-height: 100%;
}

body {
    background-image: url("/assets/images/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
}

header {
    height: 50px;
    text-align: center;
    margin-top: 22px;
    margin-bottom: 22px;
}

header img {
    height: 50px;
}

.btn-default {
    color: #333;
    background-color: #dae5e0;
    border-color: #dae5e0;
}
.btn-default:hover {
    color: #333;
    background-color: #FFFFFF;
    border-color: #dae5e0;
}
.popup-overlay {
    background: #0005;
    z-index: 1000;
}

.popup-content {
    z-index: 1010 !important;
    border: none!important;
    border-radius: 6px;
    box-shadow: none!important;
    padding: 30px!important;
    width: 330px!important;
}

.popup-arrow {
    display: none!important;
}
.mews-distributor-widget {

    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 0;
    margin-bottom: 60px;
}

.mews-distributor-widget .react-datepicker {

    border: 2px solid #fe3700 !important;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(100, 100, 100);
    -moz-box-shadow: 0px 0px 15px 0px rgb(100, 100, 100);
    box-shadow: 0px 0px 15px 0px rgb(100, 100, 100);
}

.mews-distributor-widget .react-datepicker__navigation--next {

    border-left-color: #FF3600 !important;
}

.mews-distributor-widget .react-datepicker__navigation--previous {

    border-right-color: #FF3600 !important;
}

.mews-distributor-widget .react-datepicker-wrapper {
    width: 100%;
}

.mews-distributor-widget .react-datepicker__triangle {
    display: none;
}

.mews-distributor-widget .react-datepicker__header {
    background-color: #ffdad3 !important;
    border-bottom: none;
}

.mews-distributor-widget .react-datepicker__day--selected {
    background-color: #FF3600 !important;
}


/**
 * React Datepicker Bugfix for "small" calendar
 * @see: https://github.com/Hacker0x01/react-datepicker/issues/624#issuecomment-403012034
 */
.react-datepicker { font-size: 1em !important }
.react-datepicker__header { padding-top: 0.8em !important}
.react-datepicker__month { margin: 0.4em 1em !important}
.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em !important;
    line-height: 1.9em !important;
    margin: 0.166em !important;
}
.react-datepicker__current-month { font-size: 1em !important }
.react-datepicker__navigation {
    top: 1em !important;
    line-height: 1.7em !important;
    border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous { border-right-color: #ccc !important; left: 1em !important }
.react-datepicker__navigation--next {
    border-left-color: #ccc !important;
    right: 1em !important;
    left: 220px !important
}


.react-datepicker__time-container { width: 6em !important}
.react-datepicker-time__header { font-size: 1em !important }
.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }
.react-datepicker__time-box {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width:100% !important
}
.react-datepicker__time-list { padding: 0 !important; }

button.mews-date-btn {
    background: transparent;
    border: none;
    outline: none;
    margin-left: auto;
    display: block;
    margin-right: auto;
}

.mews-DateBox {

    text-align: center;
    border-color: #999999;
    padding: 15px;
    width: 100%;
}

.mews-DateBox .day-numeric {

    display: block;
    font-size: 3em;
    padding-top: 0;
}

.mews-DateBox .month,
.mews-DateBox .year {

    font-size: 1.2em;
    padding: 5px 0;
}

.mews-DateBox .day {

    display: block;
    font-size: 1.2em;
    color: #999;
}

.mews-DateSelectSection {

    position: relative;
}

.mews-fromTo-arrow {
    display: block;
    width: 30px;
    height: 10px;
    position: absolute;
    top: 70px;
    left: calc(50% - 15px);
    background-image: url(/assets/images/arrow.png);
    background-position: left;
    background-repeat: no-repeat;

}

.mews-startDateSelector {

    display: inline-block;
    width: 50%;
}

.mews-distributor-widget .mews-startDateSelector .react-datepicker-popper {
    top: -135px !important;
    left: 50px !important;
}

.mews-endDateSelector {

    display: inline-block;
    width: 50%;
}

.mews-distributor-widget .mews-endDateSelector .react-datepicker-popper {
    top: -135px !important;
    left: -50px !important;
}

.mews-AvailabilitySection {
    padding: 0 30px 30px 30px;
    text-align: center;
}

.mews-AvailabilitySection .mews-AvailabilitySection-isLoading {

    background-image: url("/assets/images/loading.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    height: 55px;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
}

.mews-AvailabilitySection .btn-primary {
    color: #fff;
    background-color: #FF3600;
    border-color: #FF3600;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    padding: 15px;
}

.mews-AvailabilitySection .mews-RateItem {
    border-top: 1px solid #dae5e0;
}

.mews-AvailabilitySection .mews-RateList .mews-RateItem:first-child {
    border-top: none;
}
.mews-AvailabilitySection .mews-RateList .mews-RateItem:last-child {
    margin-bottom: 15px;
}

.mews-AvailabilitySection .mews-RateItem .fromPrice {

    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 15px;
}

.mews-AvailabilitySection .mews-RateItem h3.title {

    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 5px;
}

.mews-AvailabilitySection .mews-RateItem p.description {

    font-size: 1em;
    color: #999999;
    padding-bottom: 0;
}

.mews-AvailabilitySection .mews-availabilityBox.not-available p {
    background: #ff360030;
    padding: 15px;
    margin-bottom: 0;
}

.mews-AttributeSelectSection {
    width: 100%;
    display: table;
    padding: 0 30px 15px 30px;
    margin: 0;
    text-align: center;
}

.mews-AttributeSelectSection-Voucher-Wrapper {

}

.mews-AttributeSelectSection-Voucher-Wrapper .mews-AttributeSelectSection-VoucherBtn {

}

.mews-AttributeSelectSection-Voucher-Wrapper .form-control {

    border-color: #dae5e0;
}


.mews-AttributeSelectSection-Voucher-Wrapper .mews-VoucherInfo span{

    display: none;
}

.mews-AttributeSelectSection-Voucher-Wrapper.is-invalid .mews-VoucherInfo .invalid{

    margin-top: 5px;
    display: block;
    color: #FF3600;
}

.mews-AttributeSelectSection-Voucher-Wrapper.is-valid .mews-VoucherInfo .valid{

    margin-top: 5px;
    display: block;
    margin-top: 5px;
}

.mews-AttributeSelectSection-Voucher-Wrapper.is-invalid input {

    background-color: #ffdad3;
    border-color: #FF3600;
}

.mews-AttributeSelectSection-Voucher-Wrapper.is-invalid button {

    background-color: #FF3600;
    border-color: #FF3600;
    color: #FFFFFF;
}

.mews-CabsSelector {
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
}

.mews-CabsSelector-ItemAdd {

    width: 100%;
}

.mews-CabsSelector-List {

    margin-bottom: 15px;
    margin-left: -30px;
    margin-right: -30px;
}

.mews-CabsSelector-Item {
    line-height: 35px;
    font-size: 1.2em;
    padding: 15px 30px;
}

.mews-CabsSelector-ItemRemove {

    display: inline-block;
    width: 10%;
    text-align: right;
}

.mews-CabsSelector-ItemTitle {
    text-align: right;
    position: relative;
    width: 40%;
    display: inline-block;
    padding-right: 30px;
}

.mews-CabsSelector-ItemPersonCount {

    display: inline-block;
    width: 50%;
}
.mews-CabsSelector-ItemRemove-btn {

    border: none;
    background: transparent;
    background-image: url(/assets/images/remove-btn.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    width: 25px;
    height: 25px;
    padding: 0;
    line-height: 35px;
    vertical-align: middle;
    position: relative;

}

.mews-CabsSelector-List .mews-CabsSelector-Item:first-child .mews-CabsSelector-ItemRemove-btn{

    display: none;
}

.mews-CabsSelector-Item .form-control {
    border: none;
    box-shadow: none;
}

.mews-CabsSelector-ItemPersonCount .react-numeric-input b {

    color: #333 !important;
    background-color: #dae5e0 !important;
    border-color: #dae5e0 !important;
    font-weight: normal !important;
}

.mews-CabsSelector-ItemPersonCount .react-numeric-input b:hover {

    color: #333 !important;
    background-color: #FFFFFF !important;
    border-color: #dae5e0 !important;
    cursor: pointer !important;
}




